import { Spin } from "antd";
import React from "react";
import styled from "styled-components";

const SpinerBox = (props) => {

  const { height } = props;

  const SpinnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${(props) => props?.height};
  `;

  return (
    <SpinnerWrapper height={height || '100%'}>
      <Spin size="large" />
    </SpinnerWrapper>
  );
};

export default SpinerBox;
