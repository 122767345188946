import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    venderData:undefined,
}

export const vendorDetailsSlice = createSlice({
    name: 'vendorDetails',
    initialState,
    reducers: {
        setVendorDetails: (state,action) => {
            state.venderData = action.payload
        }
    }
})

export const { setVendorDetails } = vendorDetailsSlice.actions

export default vendorDetailsSlice.reducer