import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isAuthenticate:false,
    userData:null,
}

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setAuthentication: (state,action) => {
            state.isAuthenticate = action.payload
        },
        setUserData: (state,action) => {
            state.userData = action.payload
        },
    }
})

export const { setAuthentication, setUserData } = authenticationSlice.actions

export default authenticationSlice.reducer