// import { HOST } from "../../constants/api";
import axios from 'axios';
import handleApiCall, { AuthHeader } from '../handleApi'

// const Cookies = require('js-cookie');
// const authToken = Cookies.get('authToken');
// const empId = Cookies.get('emp_id');

// console.log('auth api authToken', authToken);

const HOST = process.env.REACT_APP_HOST;

export const InternalApi = {
    getDistributor: (
        data,
        onSuccess,
        onError,
    ) => {
        return handleApiCall(
            axios.post(`${HOST}/distributor`,data),
            onSuccess,
            onError,
        );
    },
    getRoofType: (
        data,
        onSuccess,
        onError,

    )=>{
        return handleApiCall(
            axios.post(`${HOST}/roofs`),
            onSuccess,
            onError,
        );
    },
    getSolarPannel:(data,onSuccess,onError)=>{
        return handleApiCall(
            axios.post(`${HOST}/solar-panels`,data),
            onSuccess,
            onError,
        );
    },
    getConfiguration:(data,onSuccess,onError)=>{
        return handleApiCall(
            axios.post(`${HOST}/price-data`,data),
            onSuccess,
            onError,
        );
    },
    orderDetails:(data,onSuccess,onError)=>{
        return handleApiCall(
            axios.post(`${HOST}/submit-order`,data),
            onSuccess,
            onError,
        );
    },
    getAuthCheck:(data,onSuccess,onError)=>{
        return handleApiCall(
            axios.post(`${HOST}/auth-check`,data),
            onSuccess,
            onError,
        );
    },
    addCustomer:(data,onSuccess,onError)=>{
        return handleApiCall(
            axios.post(`${HOST}/add-customer`,data),
            onSuccess,
            onError,
        );
    }
}