import { lazy } from "react";

const PublicRoutes = [
  {
    path: "/",
    component: lazy(() => import("../pages/publicPages/Home/index")),
    exact: true,
  },
  {
    path: "/roof-details/:id",
    component: lazy(() => import("../pages/publicPages/RoofDetail/index")),
    exact: true,
  },
  {
    path: "*",
    component: lazy(() => import("../pages/ErrorPage")),
    exact: true,
  },
]

const PrivateRoutes = [
  // {
  //   path: "/dashboard1",
  //   component: lazy(() => import("../pages/privatePages/Dashboard1")),
  //   exact: true,
  // },
  // {
  //   path: "/dashboard2",
  //   component: lazy(() => import("../pages/privatePages/Dashboard2")),
  //   exact: true,
  // },
];

export { PublicRoutes, PrivateRoutes };