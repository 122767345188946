// import { Layout, Spin } from "antd";
import React, { Suspense, useEffect, useState } from "react";
import "./LayoutBox.scss";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Header from "../Header";
// import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import { venderDetails } from "../../constants";
import { setVendorDetails } from "../../reducers/VendorDetails";
import SpinerBox from "../SpinerBox";
import { InternalApi } from "../../api/vender";
import { PlusCircleOutlined } from "@ant-design/icons";
import GIF from "../../assets/images/loading.gif";
import {
  Button,
  Col,
  FloatButton,
  Form,
  Input,
  Popover,
  Row,
  notification,
} from "antd";
import { onlyNumberForAuth } from "../../utils/commonFunction";
import { setAuthentication, setUserData } from "../../reducers/Authentication";
// import GIF1 from '../../assets/images/loadingBlue.gif'

const LayoutBox = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { venderData } = useSelector((state) => state.vendorDetails);
  const { isAuthenticate } = useSelector((state) => state.authentication);
  const { show } = useSelector((state) => state.popoverShow);

  const [isLoading, setIsLoading] = useState(false);
  const [isAuthPopOpen, setIsAuthPopOpen] = useState(false);

  const handleSuccessGetDistributor = (response) => {
    if (response?.status === 200) {
      dispatch(setVendorDetails(response?.data?.data));
      setIsLoading(false);
    }
  };

  const handleErrorGetDistributor = (error) => {
    dispatch(setVendorDetails(venderDetails));
    setIsLoading(false);
    navigate("/not-found");
  };

  useEffect(() => {
    InternalApi.getDistributor(
      {},
      handleSuccessGetDistributor,
      handleErrorGetDistributor
    );

    setIsLoading(true);
  }, []);

  const handleSuccessAuthCheck = (response) => {
    if (response?.status === 200) {
      dispatch(setAuthentication(true));
      dispatch(setUserData(null));
      setIsAuthPopOpen(false);
      notification.success({
        message: "Gratulerer, du er autentisert.",
        duration: 3,
      });
    }
  };

  const handleErrorAuthCheck = (error) => {
    dispatch(setAuthentication(false));
    setIsAuthPopOpen(false);
    notification.error({
      message: error?.message || "Somthing went wrong! Please try again later",
      duration: 3,
    });
  };

  const onFinish = (values) => {
    InternalApi.getAuthCheck(
      { auth_code: values?.authCode },
      handleSuccessAuthCheck,
      handleErrorAuthCheck
    );
  };

  const onClickFloatButton = () => {
    // dispatch(setAuthentication(true));
    setIsAuthPopOpen(!isAuthPopOpen);
  };

  const content = (
    <div>
      <Form
        name="authForm"
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={false}
      >
        <div
          className="pr-3 pl-3 text-gray text-center"
          style={{ color: venderData?.colorGroup?.textColor }}
        >
          Vennligst oppgi din personlige kode for å få tilgang.
        </div>
        <div
          className="mb-4 pr-3 pl-3 text-gray text-center"
          style={{ color: venderData?.colorGroup?.textColor }}
        >
          (har du ikke kode, send epost til <br />
          <span style={{ color: venderData?.colorGroup?.primary }}>
            info@mestertaket.no
          </span>
          )
        </div>
        <Row gutter={12}>
          <Col xs={17} className="pl-3">
            <Form.Item
              name="authCode"
              rules={[
                {
                  required: true,
                  message: "Skriv inn autentiseringskoden.",
                },
                {
                  min: 6,
                  message: "Autentiseringskoden må være 6-sifret",
                },
                {
                  max: 6,
                  message: "Autentiseringskoden må være 6-sifret",
                },
              ]}
              // normalize={(e) => onlyNumberForAuth(e)}
            >
              <Input
                className="text-center"
                size="large"
                placeholder="------"
              />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item className="d-flex align-items-center justify-content-end">
              <Button type="primary" size="large" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
          <Col xs={1}></Col>
        </Row>
      </Form>
    </div>
  );

  return (
    <>
      {isLoading ? (
        <div className="h-100 d-flex align-items-center justify-content-center">
          <img src={GIF} alt="loading" srcSet="" />
        </div>
      ) : (
        <>
          <Header />
          <div style={{ height: "86vh" }}>
            <Suspense fallback={<SpinerBox height="100%" />}>
              <Outlet />
            </Suspense>
          </div>
          {!isAuthenticate
            ? show && (
                <Popover
                  content={content}
                  title={
                    <div className="fs-24 text-center">Adgangskontroll</div>
                  }
                  destroyTooltipOnHide={true}
                  trigger="click"
                  onOpenChange={(e) => setIsAuthPopOpen(e)}
                  arrow={false}
                  placement="topLeft"
                  open={isAuthPopOpen}
                >
                  <FloatButton
                    icon={
                      <PlusCircleOutlined
                        style={{
                          fontSize: "26px",
                          color: `${venderData?.colorGroup?.primary}`,
                        }}
                        rotate={isAuthPopOpen ? 45 : 0}
                      />
                    }
                    onClick={onClickFloatButton}
                    className="floatButton"
                    style={{
                      right: window.innerWidth < 1200 ? 20 : 30,
                      bottom: window.innerWidth < 1200 ? 20 : 30,
                      zIndex: 999,
                    }}
                  />
                </Popover>
              )
            : null}
        </>
      )}
    </>
  );
};

export default LayoutBox;
