import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    show : true,
}

export const popoverSlice = createSlice({
    name: 'popoverShow',
    initialState,
    reducers: {
        setPopover: (state,action) => {
            state.show = action.payload
        }
    }
})

export const { setPopover } = popoverSlice.actions

export default popoverSlice.reducer