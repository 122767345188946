export const antConfig = (data) => {

  let configResponse = {
    token: {
      colorPrimary: data?.colorGroup?.primary || "red",
      borderRadius: "6px",
      colorTextHeading: data?.colorGroup?.textColor || "black",
      colorTextLabel: data?.colorGroup?.textColor || "black",
      colorTextDescription: data?.colorGroup?.textColor || "black",
      fontSize:16,
    },
  };
  return configResponse;
};
