import { configureStore } from '@reduxjs/toolkit';

import vendorDetailsReducer from '../reducers/VendorDetails';
import authenticationReducer from '../reducers/Authentication';
import popoverHiderReducer from '../reducers/PopoverHider';

export default configureStore({
    reducer: {
        vendorDetails: vendorDetailsReducer,
        authentication: authenticationReducer,
        popoverShow:popoverHiderReducer
    }
});